import axios from 'axios';

export default class InfoService {
  static async getInfo(type) {
    return axios.get(`/info-pages/${type}`);
  }

  static async sendForm(data) {
    return axios.post('/info-pages/send-form', {
      email: data.email,
      subject: data.subject,
      message: data.message,
    });
  }
}

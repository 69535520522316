/* eslint-disable */

export const en = {
  // SIGNUP
  CREATE_ACCOUNT: 'Create an account',
  PHONE: 'Phone Number',
  CONTINUE: 'Continue',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  LOGIN: 'Log In',
  VERIFY_PHONE_NUMBER: 'Verify phone number',
  ENTER_THE_CODE_WE_HAVE_SENT: 'Enter the code we’ve sent by SMS to',
  HAVE_NOT_RECEIVED_SMS: 'Haven’t received SMS?',
  SEND_AGAIN: 'Send again',
  BACK: 'Back',
  ADD_PERSONAL_DETAILS: 'Add personal details',
  FIRST_NAME: 'First name',
  EMAIL: 'Email',
  LAST_NAME: 'Last name',
  ADD_NETWORK_DETAILS: 'Add network details',
  MOBILE_NETWORK_CARRIER: 'Mobile network carrier',
  PHONE_PLAN: 'Phone plan',
  MONTHLY: 'Monthly',
  PREPAID: 'Prepaid',
  SIGN_UP: 'Sign Up',
  TERMS_CONDITIONS: 'Terms and Conditions',
  I_AGREE: 'I agree to the Terms and Conditions and Privacy Policy',
  PLEASE_AGREE: 'Please agree to the Terms and Conditions',
  // LOGIN
  FORGOT_PASSWORD: 'Forgot password?',
  PASSWORD: 'Password',
  NEW_TO_TELCOREWARDS: 'New to Telco Rewards?',
  // RESET PASSWORD BY PHONE
  DO_NOT_HAVE_ACCESS_TO_PHONE: 'Do not have access to this phone?',
  USE_EMAIL: 'Use email',
  SEND_CODE: 'Send code',
  SET_NEW_PASSWORD: 'Set new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  // RESET PASSWORD BY EMAIL
  DO_NOT_HAVE_ACCESS_TO_EMAIL: 'Do not have access to this email?',
  ENTER_THE_EMAIL_ADDRESS_YOU_REGISTER_WITH:
    'Enter the email address you used to register with.',
  BACK_TO_LOGIN: 'Back to Log in',
  CHECK_YOUR_INBOX: 'Check your inbox',
  LINK_WAS_SENT_TO:
    'A link was sent to {{email}} to carrierReset your password. If you don’t see it in your inbox, remember to check your spam folder.',
  // HOME PAGE
  SEARCH_STORES: 'Search stores',
  FEATURES_STORES: 'Featured Stores',
  ALL_STORES: 'All Stores',
  E_SIMS: 'eSims',
  FASHION: 'Fashion',
  ENTERTAINMENT: 'Entertainment',
  ELECTRONICS: 'Electronics',
  FOOD_AND_GROCERY: 'Food & Grocery',
  BABY_KIDS_TOYS: 'Baby, Kids & Toys',
  BOOKS: 'Books',
  HOME_AND_GARDEN: 'Home & Garden',
  BEAUTY_AND_HEALTH: 'Beauty & Health',
  SPORTS_AND_OUTDOOR: 'Sports & Outdoor',
  AUTO: 'Auto',
  PET_SUPPLIES: 'Pet Supplies',
  HARDWARE_AND_TOOLS: 'Hardware & Tools',
  FILTER: 'Filter',
  HOW_TO_USE_TELCOREWARDS: 'HOW TO USE TELCO REWARDS?',
  SELECT_ONLINE_SHOP: 'Select an online shop',
  GO_TO_ONE_OF_2930_STORES: 'Go to one of 2930+ stores',
  COMPLETE_PURCHASE: 'Complete purchase',
  PURCHASE_GOODS_AND_SERVICES: 'Purchase goods and services as usual',
  RECEIVE_CASHBACK: 'Receive cashback',
  GET_PART_OF_THE_MONEY: 'Get part of the money you spent back',
  DOWNLOAD_THE_APP: 'Download the App',
  // SETTINGS
  PERSONAL_INFORMATION: 'Personal information',
  MISSING_TRANSACTION: 'Missing transaction',
  HOW_TELCO_REWARDS_WORK: 'How Telco Rewards work',
  GET_HELP: 'Get help',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  PRIVACY_POLICY: 'Privacy Policy',
  CONTACT_US: 'Contact Us',
  TELECOM: 'Telecom',
  FAQ: 'FAQ',
  BLOG: 'Blog',
  LOGOUT: 'Log out',
  AVAILABLE: 'AVAILABLE',
  // STORE PAGE
  SHOP_NOW: 'Shop Now',
  LOG_IN_TO_SHOP: 'Log in to shop',
  BASE_REWARD: 'Base reward',
  // CASHBACK
  NO_REWARDS_YET: 'No rewards yet',
  REDUCE_YOUR_MOBILE_BILL: 'Reduce your mobile bill with cash-back.',
  DONT_HAVE_ACCOUNT: 'Don’t have an account?',
  CASHBACK: 'Cash-back',
  WITHDRAWALS: 'Withdrawals',
  PENDING: 'PENDING',
  TOTAL: 'TOTAL',
  DAYS: 'days',
  DAY: 'day',
  WITHDRAW: 'Withdraw',
  ADDED_TO_MOBILE_BALANCE: 'ADDED TO MOBILE BALANCE',
  STILL_AVAILABLE: 'STILL AVAILABLE',
  AVAILABLE_CASHBACK: 'Available',
  RETURNED: 'Returned',
  SHOP_FOR_MORE_CASH_BACK: 'Shop for more cash-back',
  // MISSING TRANSACTION
  REPORT_MISSING_TRANSACTION: 'Report a missing transaction',
  STORE: 'Store',
  DATE_OF_PURCHASE: 'Date of purchase',
  PURCHASE_TYPE: 'Purchase type',
  PAYMENT_METHOD: 'Payment method',
  PROOF_OF_PAYMENT: 'Proof of payment',
  PLEASE_UPLOAD_RECEIPT:
    'Please upload a receipt or bank statement to show the proof of purchase value, transaction date, payment card details and retailer. We need to see all this information to confirm your transaction with the retailer',
  FOR_SECURITY:
    'For security, please do not include your full card number, expiry date of card or any other account information other than the last 4 digits of the card, transaction date, amount and retailers',
  ORDER_AMOUNT: 'Order amount',
  UPLOAD_PHOTO: 'Upload Photo',
  SUBMIT_FOR_REVIEW: 'Submit for review',
  MOBILE: 'Mobile',
  DESKTOP: 'Desktop',
  CREDIT_CARD: 'Credit Card',
  DEBIT_CARD: 'Debit Card',
  INSTALLMENT_PAYMENTS: 'Installment payments',
  PLEASE_ENTER_VALID_AMOUNT: 'Please enter valid order amount',
  PLEASE_ENTER_VALID_COMMENT: 'Please enter valid comment',
  COMMENT: 'Comment',
  // TO DO

  PLEASE_ENTER_PHONE_NUMBER_IN_FORMAT:
    'Please enter phone number in format 4165551212',
  SHOULD_CONTAIN_MINIMUM_7SYMBOLS:
    'Should contain minimum 8 symbols (1 capital, 1 number, 1 special character)',
  PLEASE_ENTER_VALID_EMAIL: 'Please enter valid email example@gmail.com',
  RESET_YOUR_PASSWORD: 'Reset your password',
  PLEASE_SELECT_VALID_MOBILE_NETWORK:
    'Please select valid mobile network carrier',
  THE_FIRST_NAME_MIN: 'The first name min 2 symbols',
  THE_LAST_NAME_MIN: 'The last name min 2 symbols',
  LOGIN_SELECT_ONLINE_SHOP: 'Log-in.Select an online shop.Click on Shop Now.',
  MAKE_PURCHASE_LIKE_NORMALLY:
    'Make a purchase like you normally would from our retail partner.',
  GET_PART_MONEY: 'Get part of the money you spent back!',
  PAY_DOWN_YOUR_PHONE: 'Pay down your pre-paid phone bill',
  ONCE_YOU_HAVE_ACCUMULATED:
    'Once you have accumulated enough cash-back, pay-down your phone-bill.',
  NO_OFFERS_FOR_STORE: ' No offers for this store',
  STORE_CANT_EMPTY: 'Store name can’t be empty',
  DATE_OF_PURCHASE_CANT_EMPTY: 'Date of purchase can’t be empty',
  PURCHASE_TYPE_CANT_EMPTY: 'Purchase type can’t be empty',
  PAYMENT_METHOD_CANT_EMPTY: 'Payment method can’t be empty',

  // For security, please do not include your full card number,expiry date of card or any other account information other thanthe last 4 digits of the card, transaction date, amount andretailers
  CARRIER: 'Carrier',
  CURRENT_BALANCE: 'Current balance',
  OFFERS_RECEIVE: 'Offers receive',
  BILLING_NUMBER: 'Prepaid',
  NEW_PASSWORD_CREATED: 'New password successfully created',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  NEW_USER_CREATED: 'New user successfully created',
  MISSING_TRANSACTION_CREATED: 'Missing transaction created',
  FILL_OUT_THIS_FORM:
    'Please fill out this form for our review, if you believe you have a missing transaction',
  GO_BACK_TO_CHANGE: 'Go back to change',
  INCREASED_CASHBACK: 'Increased cash-back',
  TOP_STORE: 'Top store',
  RECENTLY_ADDED_STORE: 'Recently added store',
  SHIPS_FROM_CANADA: 'Ships from Canada',
  VERIFICATION_CODE_IS_NOT_CORRECT: 'verification code is incorrect',
  ON_PURCHASES_OVER: 'On purchases over',
  COMUNICATION_PREFERENCE: 'Communication preferences',
  RECEIVE_OFFERS:
    'Yes, sign me up to receive offers and advice by email to help me save on my mobile bill.',
  UPDATE: 'Update',
  CHECK_EMAIL_FOR_INSTRUCTION: 'Check your email for instructions',
  PIN_NUMBER: 'Pin number',
  SERIAL: 'Serial',
  BATCH: 'Batch',
  PLEASE_WAIT_WHILE_PROCESSING:
    'Please wait while we are processing your request...',
  MIN_READ: '{{readTime}} min read',
  DELETE_ACCOUNT: 'Delete account',
  ALERT_DELETE_ACCOUNT:
    'Rather save money on your own mobile bill all by yourself? You can delete your account with the button below. Just keep in mind that your information can`t be recovered once you decide to go, including any rewards accumulated in your wallet.',
  // to do
  // Communication preferences
  // Yes, please sign me up to receive offers by email

  // contact from
  SUBJECT: 'Subject',
  MESSAGE: 'Message',
  SEND_MESSAGE: 'Send message',
  SENDED: 'Sended',
  // HELMET
  TITLE_SEO: 'Telco Rewards | ',
  DASHBOARD_TITLE_SEO:
    'Telco Rewards | Earn Cashback on Mobile Recharge & Prepaid Top-Ups in Canada',
  BUY_PREPAID_MOBILE: 'Buy Prepaid Mobile Top-Ups and Earn Cashback',
  DASHBOARD_DESCRIPTION_SEO:
    'Telco Rewards: Buy prepaid mobile top-ups in Canada and earn cashback rewards for future purchases. Enjoy secure mobile recharge options across all carriers.',
  DASHBOARD_KEYWORDS_SEO: 'Telco Rewards Dashboard',
  TERMS_AND_CONDITIONS_TITLE_SEO: 'Telco Rewards | Terms and Conditions',
  TERMS_AND_CONDITIONS_DESCRIPTION_SEO: 'Telco Rewards, Terms and Conditions',
  TERMS_AND_CONDITIONS_KEYWORDS_SEO: 'Telco Rewards Terms and Conditions',
  CONTACT_US_TITLE_SEO: 'Telco Rewards | Contact Us',
  CONTACT_US_DESCRIPTION_SEO: 'Telco Rewards, Contact Us',
  CONTACT_US_KEYWORDS_SEO: 'Telco Rewards Contact Us',
  FAQ_TITLE_SEO: 'Telco Rewards | FAQ',
  FAQ_DESCRIPTION_SEO: 'Telco Rewards, FAQ',
  FAQ_KEYWORDS_SEO: 'Telco Rewards FAQ',
  HOW_IT_WORKS_TITLE_SEO: 'Telco Rewards | How It Works',
  HOW_IT_WORKS_DESCRIPTION_SEO: 'Telco Rewards, How It Works',
  HOW_IT_WORKS_KEYWORDS_SEO: 'Telco Rewards How It Works',
  PRIVACY_POLICY_TITLE_SEO: 'Telco Rewards | Privacy Policy',
  PRIVACY_POLICY_DESCRIPTION_SEO: 'Telco Rewards, Privacy Policy',
  PRIVACY_POLICY_KEYWORDS_SEO: 'Telco Rewards Privacy Policy',
  TELECOMS_TITLE_SEO: 'Telco Rewards | Telecoms',
  TELECOMS_DESCRIPTION_SEO: 'Telco Rewards, Telecoms',
  TELECOMS_KEYWORDS_SEO: 'Telco Rewards Telecoms',
  BLOG_TITLE_SEO: 'Telco Rewards | Blog',
  BLOG_DESCRIPTION_SEO: 'Telco Rewards, Blog',
  BLOG_KEYWORDS_SEO: 'Telco Rewards Blog',
  SIGN_UP_TITLE_SEO: 'Telco Rewards | Sign Up',
  SIGN_IN_TITLE_SEO: 'Telco Rewards | Sign In',
  RESET_PASSWORD_TITLE_SEO: 'Telco Rewards | Reset Password',
  CASHBACK_TITLE_SEO: 'Telco Rewards | Cashback',
  CASHBACK_DESCRIPTION_SEO: 'Telco Rewards, Cashback',
  CASHBACK_KEYWORDS_SEO: 'Telco Rewards Cashback',
  MISSING_TRANSACTION_TITLE_SEO: 'Telco Rewards | Missing Transaction',
  MISSING_TRANSACTION_DESCRIPTION_SEO: 'Telco Rewards | Missing Transaction',
  MISSING_TRANSACTION_KEYWORDS_SEO: 'Telco Rewards | Missing Transaction',
  BUY_TOP_UP_PIN: 'Buy Top Up Pin',
  FEATURED_TOP_UP_PIN: 'Featured Top Up Pin',
  SEARCH_CARRIERS: 'Search carriers',
  PIN_AMOUNT: 'PIN AMOUNT',
  CASHBACK_TO_THE_WALLET: 'Cashback',
  BUY: 'Buy',
  NO_PINS_FOR_CARRIER: 'No PINs for this carrier',
  CHECKOUT: 'Checkout',
  BILLING_INFORMATION: 'Billing Information',
  PAYMENT: 'Payment',
  ORDER_SUMMARY: 'Order Summary',
  AMOUNT_SENT: 'Amount sent',
  OPERATOR: 'Operator',
  APPLY_CASHBACK: 'Apply cashback',
  MINIMIZE_THE_TOP_UP: 'Maximize the top up',
  TO_APPLY_CASHBACK:
    'To apply cashback to the order summary, please, proceed with the payment',
  PHONE_NUMBER: 'Phone number',
  ADDRESS: 'Address',
  CITY: 'City',
  POST_CODE: 'Post Code',
  COUNTRY: 'Country',
  PROVINCE: 'Province',
  CHOOSE_PROVINCE: 'Choose province',
  PROCEED_TO_PAYMENT: 'Proceed to Payment',
  CARD_NUMBER: 'Card Number',
  EXPIRATION: 'Expiration',
  CVC: 'CVC',
  PLACE_ORDER: 'Place order',
  BACK_TO_BILLING_INFORMATION: 'Back to Billing Information',
  SALES_TAX: 'Sales Tax',
  PIN: 'PIN',
  IS_REQUIRED: 'is required',
  PLEASE_VALID_EMAIL: 'Oops! Please enter a valid email address.',
  PLEASE_VALID_PHONE: 'Oops! Please enter a valid phone number, just digits.',
  PHONE_NUMBER_HAS: 'Phone number has to have 7 digits at least',
  THANK_YOU_FOR_YOUR_ORDER: 'Thank you for your order!',
  YOUR_RECHARGE_CODE_IS_BELOW:
    'Your recharge code is below and has been sent to',
  CANNOT_FIND_OUR_EMAIL: 'Can’t find our email? Check your spam folder.',
  YOUR_CODE: 'Your Code',
  YOUR_ORDER: 'Your Order',
  ORDER_NUMBER: 'Order Number',
  DATE: 'Date',
  SUBTOTAL: 'Subtotal',
  CASHBACK_APPLIED: 'Cashback Applied',
  PROBLEM_WITH_YOUR_CODE: 'Problem with your code? Use our',
  PAGE: 'page',
  SEE_THE: 'See the',
  AND: 'and',
  FOR_TELCO_REWARD: 'for Telco Rewards',
  BACK_TO_SHOPPING: 'Back to Shopping',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  FAILED_TO_COPY: 'Failed to copy',
  NO_RESULTS_FOUND: 'No results found',
  SALE_IS_FINAL: 'Sale Is Final. Purchases Are Not Refundable',
  BUY_TOP_UP: 'Buy',
  PREPAID_TOP_UPS_AND_EARN: 'Prepaid Top-Ups and Earn Cashback Rewards',
  LOADING: 'Loading'
};

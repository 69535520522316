import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BannerService from '../../api/services/BannerService';
import { getError } from '../../utils/helpers';

const initialState = {
  banners: [],
  isLoading: false,
};

export const getBanners = createAsyncThunk(
  'banner/getBanners ',
  async ({ languageCode, type }, thunkAPI) => {
    try {
      const response = await BannerService.getBanners({ languageCode, type });
      return response?.data?.items;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const bannerSlice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getBanners.fulfilled]: (state, action) => {
      state.banners = action.payload;
      state.isLoading = false;
    },
    [getBanners.pending]: (state) => {
      state.isLoading = true;
    },
    [getBanners.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { reset } = bannerSlice.actions;
export default bannerSlice.reducer;

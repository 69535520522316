import axios from 'axios';

export default class CheckoutService {
  static async getTaxes(body) {
    return axios.post('/payments/stripe/calculate-taxes', {
      ...body,
    });
  }

  static async doPayment(body) {
    return axios.post('/payments/stripe/checkout', {
      ...body,
    });
  }
}

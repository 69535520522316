import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import storesSlice from './slices/storesSlice';
import cashbackSlice from './slices/cashbackSlice';
import withdrawMoneySlice from './slices/withdrawMoneySlice';
import infoSlice from './slices/infoSlice';
import blogSlice from './slices/blogSlice';
import bannerSlice from './slices/bannerSlice';
import carriersSlice from './slices/carriersSlice';
import checkoutSlice from './slices/checkoutSlice';

export const store = configureStore({
  reducer: {
    auth: authSlice,
    stores: storesSlice,
    cashback: cashbackSlice,
    withdrawMoney: withdrawMoneySlice,
    info: infoSlice,
    blog: blogSlice,
    banner: bannerSlice,
    carriers: carriersSlice,
    checkout: checkoutSlice,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: process.env.NODE_ENV === 'development',
});

import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Input from '@mui/material/Input';
import { useTranslation } from 'react-i18next';

import useMediaQuery from '@mui/material/useMediaQuery';
import LanguageSwitcher from './lib/LanguageSwitcher';
import logo from '../assets/images/logos/logo.svg';
import frenchLogo from '../assets/images/logos/frenchLogo.svg';

import { ReactComponent as SearchIcon } from '../assets/images/icons/searchIcon.svg';
import { setSearch } from '../redux/slices/storesSlice';
import UserMenu from './lib/UserMenu';
import useDebounce from '../hooks/useDebounce';
import BuyTopUpPinButton from './lib/BuyTopUpPinButton';
import { setCarrierSearch } from '../redux/slices/carriersSlice';
import { useCheckIsCarrierRoute } from '../hooks/useCheckIsCarrierRoute';

const LoginedHeader = ({ availableBalance }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const matchesWidth = useMediaQuery('(max-width:600px)');
  const { isCarrier } = useCheckIsCarrierRoute();

  const handleChange = (e) => {
    const { value } = e.target;
    if (value.trim().length) {
      setSearchTerm(value);
    } else {
      setSearchTerm('');
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === ' ' && searchTerm.length === 0) {
      return e.preventDefault();
    }
    return null;
  };

  useDebounce(searchTerm, 500, setSearch);
  useDebounce(searchTerm, 500, setCarrierSearch);

  return (
    <>
      <div className={classes.headerButtonsContainerForMobile}>
        <BuyTopUpPinButton />
      </div>

      <header className={classes.headerContainer}>
        <div
          className={classes.logoContainer}
          onClick={() => {
            navigate('/');
          }}
        >
          <img
            className={classes.logo}
            src={i18n.language === 'en' ? logo : frenchLogo}
            alt='logo'
            width={192}
            height={20}
          />
        </div>
        <div
          className={`${classes.searchContainer} ${
            searchTerm.length > 0 && classes.activeSearch
          }`}
        >
          <SearchIcon />
          <Input
            onChange={(e) => handleChange(e)}
            value={searchTerm}
            onKeyDown={handleKeyDown}
            placeholder={isCarrier ? t('SEARCH_CARRIERS') : t('SEARCH_STORES')}
            disableUnderline
            fullWidth
            sx={(theme) => ({
              marginLeft: '8px',

              [theme.breakpoints.down('sm')]: {
                paddingLeft: '0px',
                fontSize: '12px',
              },
            })}
          />
        </div>
        <BuyTopUpPinButton hidden={matchesWidth} />
        <LanguageSwitcher />
        <div
          className={`${classes.availablePriceContainer} ${
            pathname === '/cashback' ? classes.active : ''
          }`}
          onClick={() => navigate('/cashback')}
        >
          <div className={classes.priceTitle}>{t('AVAILABLE')}</div>
          <div className={classes.price}>
            {`$ ${Number(availableBalance).toFixed(2) || Number(0).toFixed(2)}`}
          </div>
        </div>
        <UserMenu />
      </header>
    </>
  );
};
export default LoginedHeader;

const useStyles = makeStyles((theme) => ({
  headerButtonsContainerForMobile: {
    alignItems: 'center',
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
    },
  },
  headerContainer: {
    paddingInline: '72px',
    justifyContent: 'center',
    height: '96px',
    borderBottom: '1px solid #EAEAEA',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter',
    [theme.breakpoints.down('sm')]: {
      height: '64px',
      paddingInline: '16px',
    },
  },
  activeSearch: {
    border: '1px solid #33CC55!important',
  },
  logoContainer: {
    [theme.breakpoints.down('xs')]: {
      overflow: 'hidden',
      maxWidth: '48px',
      minWidth: '40px',
    },
  },
  logo: {
    height: '20px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      width: '116px',
      height: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '110px',
    },
  },
  searchContainer: {
    paddingLeft: '19px',
    width: '368px',
    height: '48px',
    border: '1px solid #EAEAEA',
    borderRadius: '26px',
    display: 'flex',
    alignItems: 'center',
    marginRight: '112px',
    marginLeft: '230px',
    [theme.breakpoints.down('md')]: {
      marginRight: '13px',
      marginLeft: '13px',
      height: '35px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '7px',
    },
  },
  searchIcon: {
    width: '14px',
    height: '14px',
  },
  availablePriceContainer: {
    width: '176px',
    height: '48px',
    border: '1px solid #EAEAEA',
    borderRadius: '26px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginLeft: '16px',
    cursor: 'pointer',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  active: { border: '1px solid #33CC55', color: '#33CC55' },
  priceTitle: {
    fontWeight: '600',
    fontSize: '12px',
    fontStyle: 'nomral',
    fontFamily: 'Inter',
    lineHeight: '16px',
    letterSpacing: '0.16em',
    textTransform: 'uppercase',
  },
  price: {
    fontWeight: '700',
    fontSize: '20px',
    fontStyle: 'nomral',
    fontFamily: 'Source Sans Pro, sans-serif',
    lineHeight: '120%',
    letterSpacing: '0.02em',
  },

  menuContainer: {
    width: '80px',
    height: '48px',
    background: '#FFFFFF',
    border: '1px solid #EAEAEA',
    borderRadius: '26px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginLeft: '16px',
  },
  menu: {
    cursor: 'pointer',
  },
}));

// SIGNUP S'inscrire
/* eslint-disable */

export const fr = {
  CREATE_ACCOUNT: 'Créer un compte ',
  PHONE: 'Numéro de téléphone',
  CONTINUE: 'Continuer',
  ALREADY_HAVE_ACCOUNT: 'Vous avez déjà un compte?',
  LOGIN: 'Se connecter',
  TERMS_CONDITIONS: 'Les termes et conditions',
  I_AGREE:
    "J'accepte les termes et conditions et la politique de confidentialité",
  PLEASE_AGREE: 'Veuillez accepter les termes et conditions',
  VERIFY_PHONE_NUMBER: 'Vérifier le numéro de téléphone',
  ENTER_THE_CODE_WE_HAVE_SENT: 'Entrez le code que nous avons envoyé par SMS à',
  HAVE_NOT_RECEIVED_SMS: "Vous n'avez pas reçu de SMS ?",
  SEND_AGAIN: 'Renvoyer',
  BACK: 'Retour',
  ADD_PERSONAL_DETAILS: 'Ajouter des détails personnels',
  FIRST_NAME: 'Prénom',
  LAST_NAME: 'Nom de famille',
  EMAIL: 'Courriel',
  PASSWORD: 'Mot de passe',
  ADD_NETWORK_DETAILS: 'Ajouter les détails du réseau mobile',
  MOBILE_NETWORK_CARRIER: 'Opérateur de réseau mobile',
  PHONE_PLAN: 'Forfait téléphonique',
  MONTHLY: 'Mensuelle',
  PREPAID: 'Prépayé',
  SIGN_UP: "S'inscrire",
  FORGOT_PASSWORD: 'Mot de passe oublié?',
  NEW_TO_TELCOREWARDS: '‘Pas d’identifiant pour Les Remises Telco?',
  // // RESET PASSWORD BY PHONE
  DO_NOT_HAVE_ACCESS_TO_PHONE: "Vous n'avez pas accès à ce téléphone?",
  USE_EMAIL: 'Utiliser le courrier électronique',
  BACK_TO_LOGIN: 'Retour à la connexion',
  SEND_CODE: 'Envoyer le code',
  SET_NEW_PASSWORD: 'Définir un nouveau code',
  CONFIRM_NEW_PASSWORD: 'Confirmer le nouveau mot de passe',
  // RESET PASSWORD BY EMAIL
  ENTER_THE_EMAIL_ADDRESS_YOU_REGISTER_WITH:
    "Entrez l'adresse courriel avec laquelle vous vous êtes inscrit",
  DO_NOT_HAVE_ACCESS_TO_EMAIL: "Vous n'avez pas accès à ce courriel?",
  // BACK_TO_LOGIN: 'Retour à la connexion',
  CHECK_YOUR_INBOX: 'Vérifiez votre boîte de réception',
  LINK_WAS_SENT_TO:
    'Un lien a été envoyé à pour réinitialiser votre mot de passe{{email}}. Si vous ne le voyez pas dans votre boîte de réception, vérifier votre dossier spam.',
  // TO_RESET_YOUR_PASSWORD_CHECK_SPAM_FOLDER:
  // // HOME PAGE
  SEARCH_STORES: 'Rechercher des magasins',
  FEATURES_STORES: 'Magasins en vedette',
  ALL_STORES: 'Tous les magasins',
  E_SIMS: 'eSim',
  FASHION: 'Mode',
  ENTERTAINMENT: 'Divertissement',
  ELECTRONICS: 'Électronique',
  FOOD_AND_GROCERY: 'Alimentation & Épicerie',
  BABY_KIDS_TOYS: 'Bébé, Enfants & Jouets',
  BOOKS: 'Livres',
  HOME_AND_GARDEN: 'Maison & Jardin',
  BEAUTY_AND_HEALTH: 'Beauté et santé',
  SPORTS_AND_OUTDOOR: 'Sports et plein air',
  AUTO: 'Auto',
  PET_SUPPLIES: 'Fournitures pour animaux',
  HARDWARE_AND_TOOLS: 'Quincailleries',
  FILTER: 'Filtre',
  HOW_TO_USE_TELCOREWARDS: ' COMMENT UTILISER LES REMISES TELCO',
  SELECT_ONLINE_SHOP: 'Sélectionner une boutique en ligne',
  GO_TO_ONE_OF_2930_STORES: "Sélectionnez l'un des 2930+ magasins",
  COMPLETE_PURCHASE: "Finaliser l'achat",
  PURCHASE_GOODS_AND_SERVICES:
    "Achetez des biens et des services comme d'habitude",
  RECEIVE_CASHBACK: 'Recevoir de remises en argent.',
  GET_PART_OF_THE_MONEY:
    "Vous récupérez une partie de l'argent que vous avez dépensé",
  DOWNLOAD_THE_APP: "Télécharger l'appli",
  // // SETTINGS
  PERSONAL_INFORMATION: 'Renseignements personnels',
  MISSING_TRANSACTION: 'Où sont mes remises en argent?',
  GET_HELP: 'Aide',
  TERMS_AND_CONDITIONS: 'Termes et conditions',
  PRIVACY_POLICY: 'Politique De Confidentialité',
  CONTACT_US: 'Contactez-nous',
  TELECOM: 'Telecom',
  FAQ: 'FAQ',
  BLOG: 'Blog',
  LOGOUT: 'Se déconnecter',
  AVAILABLE: 'DISPONIBLE',
  // // STORE PAGE
  // SHOP_NOW: 'Magasiner',
  LOG_IN_TO_SHOP: 'Se connecter pour magasiner',
  BASE_REWARD: 'Récompense de base',
  SHOP_NOW: 'Magasiner',
  // // CASHBACK REMISE $
  NO_REWARDS_YET: 'Aucune récompense accumulée pour le moment',
  REDUCE_YOUR_MOBILE_BILL:
    'Réduisez votre facture mobile grâce au remises en argent.',
  DONT_HAVE_ACCOUNT: 'Pas de compte?',
  CASHBACK: 'Remise',
  DAYS: 'journées',
  DAY: 'journée',
  WITHDRAWALS: 'Retraits',
  PENDING: 'EN ATTENTE',
  AVAILABLE_CASHBACK: 'Disponible',
  TOTAL: 'Totale',
  WITHDRAW: 'Retrait',
  ADDED_TO_MOBILE_BALANCE: 'AJOUTÉ AU SOLDE MOBILE',
  STILL_AVAILABLE: 'TOUJOURS DISPONIBLE',
  RETURNED: 'Revenu',
  SHOP_FOR_MORE_CASH_BACK: 'Magasiner pour plus de remises $',
  // // MISSING TRANSACTION TRANSACTION MANQUANTE
  REPORT_MISSING_TRANSACTION: 'Où sont mes remises en argent?',
  STORE: 'Magasin',
  COMMENT: 'Commentaire',
  DATE_OF_PURCHASE: "Date d'achat",
  PURCHASE_TYPE: "Type d'achat",
  PAYMENT_METHOD: 'Mode de paiement',
  PROOF_OF_PAYMENT: 'PREUVE DE PAIEMENT',
  ORDER_AMOUNT: 'Montant de la commande',
  PLEASE_ENTER_VALID_AMOUNT: 'Please enter valid amount',
  PLEASE_ENTER_VALID_COMMENT: 'Please enter valid description',
  PLEASE_UPLOAD_RECEIPT:
    "Veuillez télécharger un reçu ou un relevé bancaire pour montrer la preuve de la valeur de l'achat, la date de la transaction, les détails de la carte de paiement et le détaillant.",
  FOR_SECURITY:
    "Pour des raisons de sécurité, veuillez ne pas inclure votre numéro de carte complet, la date d'expiration de la carte ou toute autre information de compte autre que les 4 derniers chiffres de la carte, la date de transaction, le montant et les détaillants",
  UPLOAD_PHOTO: 'Télécharger le reçu',
  SUBMIT_FOR_REVIEW: 'Soumettre',
  // PURCHASE_TYPE: "Type d'achat",
  MOBILE: 'Mobile',
  DESKTOP: 'De bureau',
  CREDIT_CARD: 'Carte de crédit',
  DEBIT_CARD: 'Carte de débit',
  INSTALLMENT_PAYMENTS: 'Par versements',
  PLEASE_ENTER_PHONE_NUMBER_IN_FORMAT:
    'Veuillez saisir votre numéro de téléphone au format 4165551212',
  SHOULD_CONTAIN_MINIMUM_7SYMBOLS:
    'Votre mot de passe doit contenir au minimum 8 caractères: au moins une lettre minuscule et une lettre majuscule, un caractère spécial et un chiffre.',
  PLEASE_ENTER_VALID_EMAIL:
    'Saisir une adresse courriel valide example@gmail.com',
  RESET_YOUR_PASSWORD: 'Réinitialisez votre mot de passe',
  PLEASE_SELECT_VALID_MOBILE_NETWORK:
    'Veuillez sélectionner un opérateur de réseau mobile valide',
  THE_FIRST_NAME_MIN: 'Prénom (min 2 symboles)',
  THE_LAST_NAME_MIN: 'Nom de famille min 2 symboles',
  LOGIN_SELECT_ONLINE_SHOP:
    "Connectez-vous.Sélectionnez une boutique en ligne.Cliquez sur 'Magasiner'",
  MAKE_PURCHASE_LIKE_NORMALLY:
    'Effectuez un achat comme vous le feriez normalement au boutique en ligne.',
  GET_PART_MONEY:
    "Recevez des récompenses $$ pour l'argent que vous avez dépensé.",
  PAY_DOWN_YOUR_PHONE: 'Payez votre facture de téléphone prépayée',
  ONCE_YOU_HAVE_ACCUMULATED:
    'Des que vous avez accumulé suffisamment de recompenses $$, payez votre comte de téléphone prépayée',
  NO_OFFERS_FOR_STORE: 'Aucune offre pour ce magasin',
  STORE_CANT_EMPTY: 'Le nom du magasin ne peut pas être vide',
  DATE_OF_PURCHASE_CANT_EMPTY: "La date d'achat ne peut pas être vide",
  PURCHASE_TYPE_CANT_EMPTY: "La date d'achat ne peut pas être vide",
  PAYMENT_METHOD_CANT_EMPTY: 'Le mode de paiement ne peut pas être vide',
  CARRIER: 'Opérateur de réseau mobile',
  CURRENT_BALANCE: 'Solde actuel',
  OFFERS_RECEIVE: 'Les offres reçoivent',
  BILLING_NUMBER: 'Prépayé',
  NEW_PASSWORD_CREATED: 'Nouveau mot de passe créé',
  PASSWORD_NOT_MATCH: 'Les mots de passe ne correspondent pas',
  NEW_USER_CREATED: 'Nouveau compte créé',
  MISSING_TRANSACTION_CREATED: 'Transaction manquante créée',
  FILL_OUT_THIS_FORM:
    "Si vous pensez qu'il vous manque une transaction, veuillez remplir ce formulaire pour notre examen",
  GO_BACK_TO_CHANGE: 'Retourne pour changer',
  INCREASED_CASHBACK: 'Remises $$ accrue',
  TOP_STORE: 'Magasin en vedette',
  RECENTLY_ADDED_STORE: 'Nouveau magasin',
  SHIPS_FROM_CANADA: 'Expédié du Canada',
  HOW_TELCO_REWARDS_WORK: 'Comment fonctionne Les Récompenses Telco',
  VERIFICATION_CODE_IS_NOT_CORRECT: 'le code de vérification est incorrect',
  ON_PURCHASES_OVER: 'Sur les achats de plus',
  COMUNICATION_PREFERENCE: 'Préférences de communication',
  RECEIVE_OFFERS:
    'Oui, inscrivez-moi pour recevoir par couriel des offres et des conseils pour économiser sur ma facture mobile.',
  UPDATE: 'Mettre à jour',
  CHECK_EMAIL_FOR_INSTRUCTION:
    'Vérifiez votre e-mail pour obtenir des instructions',
  PIN_NUMBER: 'Code PIN',
  SERIAL: 'En série',
  BATCH: 'Lot',
  PLEASE_WAIT_WHILE_PROCESSING:
    'Veuillez patienter pendant que nous traitons votre demande...',
  MIN_READ: '{{readTime}} lecture min',
  DELETE_ACCOUNT: 'Supprimer le compte',
  ALERT_DELETE_ACCOUNT:
    'Vous préférez économiser sur votre facture mobile tout seul? Vous pouvez supprimer votre compte avec le bouton ci-dessous. Vos informations ne peuvent pas être récupérées une fois que vous avez décidé de partir, y compris les récompenses accumulées dans votre portefeuille.',
  SUBJECT: 'Sujet',
  MESSAGE: 'Message',
  SEND_MESSAGE: 'Envoyer le message',
  SENDED: 'Envoyé',
  // HELMET
  TITLE_SEO: 'Les Récompenses Telco | ',
  DASHBOARD_TITLE_SEO:
    'Les Récompenses Telco | Gagnez des remises en argent sur les recharges mobiles et les recharges prépayées',
  BUY_PREPAID_MOBILE:
    'Achetez des recharges mobiles prépayées et soyez récompensé',
  DASHBOARD_DESCRIPTION_SEO:
    "Les Récompenses Telco | Achetez des recharges mobiles prépayées au Canada et obtenez des récompenses en cashback pour vos futurs achats. Profitez d'options de recharge mobile sécurisées auprès de tous les opérateurs.",
  DASHBOARD_KEYWORDS_SEO: 'Les Récompenses Telco Tableau de bord',
  TERMS_AND_CONDITIONS_TITLE_SEO:
    'Les Récompenses Telco | Termes et conditions',
  TERMS_AND_CONDITIONS_DESCRIPTION_SEO:
    'Les Récompenses Telco, Termes et conditions',
  TERMS_AND_CONDITIONS_KEYWORDS_SEO:
    'Les Récompenses Telco Termes et conditions',
  CONTACT_US_TITLE_SEO: 'Les Récompenses Telco | Contactez-nous',
  CONTACT_US_DESCRIPTION_SEO: 'Les Récompenses Telco, Contactez-nous',
  CONTACT_US_KEYWORDS_SEO: 'Les Récompenses Telco Contactez-nous',
  FAQ_TITLE_SEO: 'Les Récompenses Telco | FAQ',
  FAQ_DESCRIPTION_SEO: 'Les Récompenses Telco, FAQ',
  FAQ_KEYWORDS_SEO: 'Les Récompenses Telco FAQ',
  HOW_IT_WORKS_TITLE_SEO: 'Les Récompenses Telco | Comment ça fonctionne',
  HOW_IT_WORKS_DESCRIPTION_SEO: 'Les Récompenses Telco, Comment ça fonctionne',
  HOW_IT_WORKS_KEYWORDS_SEO: 'Les Récompenses Telco Comment ça fonctionne',
  PRIVACY_POLICY_TITLE_SEO:
    'Les Récompenses Telco | Politique de confidentialité',
  PRIVACY_POLICY_DESCRIPTION_SEO:
    'Les Récompenses Telco, Politique de confidentialité',
  PRIVACY_POLICY_KEYWORDS_SEO:
    'Les Récompenses Telco Politique de confidentialité',
  TELECOMS_TITLE_SEO: 'Les Récompenses Telco | Télécoms',
  TELECOMS_DESCRIPTION_SEO: 'Les Récompenses Telco, Télécoms',
  TELECOMS_KEYWORDS_SEO: 'Les Récompenses Telco Télécoms',
  BLOG_TITLE_SEO: 'Les Récompenses Telco | Blog',
  BLOG_DESCRIPTION_SEO: 'Les Récompenses Telco, Blog',
  BLOG_KEYWORDS_SEO: 'Les Récompenses Telco Blog',
  SIGN_UP_TITLE_SEO: "Les Récompenses Telco | S'inscrire",
  SIGN_IN_TITLE_SEO: "Les Récompenses Telco | S'identifier",
  RESET_PASSWORD_TITLE_SEO:
    'Les Récompenses Telco | Réinitialiser le mot de passe',
  CASHBACK_TITLE_SEO: 'Les Récompenses Telco | Remise en argent',
  CASHBACK_DESCRIPTION_SEO: 'Telco Rewards, Remise en argent',
  CASHBACK_KEYWORDS_SEO: 'Telco Rewards Remise en argent',
  MISSING_TRANSACTION_TITLE_SEO: 'Telco Rewards | Transaction manquante',
  MISSING_TRANSACTION_DESCRIPTION_SEO: 'Telco Rewards | Transaction manquante',
  MISSING_TRANSACTION_KEYWORDS_SEO: 'Telco Rewards | Transaction manquante',
  BUY_TOP_UP_PIN: 'Acheter des NIPs',
  FEATURED_TOP_UP_PIN: 'NIPs en Vedette',
  SEARCH_CARRIERS: 'Recherche opérateurs',
  PIN_AMOUNT: 'Montant du NIP',
  CASHBACK_TO_THE_WALLET: 'en récompense',
  BUY: 'Acheter',
  NO_PINS_FOR_CARRIER: 'Aucun code NIP pour cet opérateur',
  CHECKOUT: 'Paiement',
  BILLING_INFORMATION: 'Informations de facturation',
  PAYMENT: 'Paiement',
  ORDER_SUMMARY: 'Récapitulatif de la commande',
  AMOUNT_SENT: 'Montant envoyé',
  OPERATOR: 'Opérateur',
  APPLY_CASHBACK: 'Appliquez vos remises',
  MINIMIZE_THE_TOP_UP: "Maximiser l'application des remises",
  TO_APPLY_CASHBACK:
    'Pour appliquer vos remises au récapitulatif de la commande, veuillez procéder au paiement',
  PHONE_NUMBER: 'Numéro de téléphone',
  ADDRESS: 'Adresse',
  CITY: 'Ville',
  POST_CODE: 'Code Postal',
  COUNTRY: 'Pays',
  PROVINCE: 'Province',
  CHOOSE_PROVINCE: 'Choisir une province',
  PROCEED_TO_PAYMENT: 'Procéder au paiement',
  CARD_NUMBER: 'Numéro de carte',
  EXPIRATION: 'Expiration',
  CVC: 'CVC',
  PLACE_ORDER: 'Passer la commande',
  BACK_TO_BILLING_INFORMATION: 'Retour aux informations de facturation',
  SALES_TAX: 'Taxe de vente',
  PIN: 'NIP',
  IS_REQUIRED: 'est requis',
  PLEASE_VALID_EMAIL: 'Oops! SVP, mettez une courriel valide.',
  PLEASE_VALID_PHONE:
    'Oops! Veuillez saisir un numéro de téléphone valide, uniquement des chiffres.',
  PHONE_NUMBER_HAS: 'Le numéro de téléphone doit comporter au moins 7 chiffres',
  THANK_YOU_FOR_YOUR_ORDER: 'Nous vous remercions de votre commande!',
  YOUR_RECHARGE_CODE_IS_BELOW:
    'Votre NIP de recharge est ci-dessous et a été envoyé à',
  CANNOT_FIND_OUR_EMAIL:
    'Vous ne trouvez pas votre courriel ? Vérifiez votre dossier courrier indésirable.',
  YOUR_CODE: 'Votre NIP',
  YOUR_ORDER: 'Votre Commande',
  ORDER_NUMBER: 'Order Number',
  DATE: 'Date',
  SUBTOTAL: 'Sous-Total',
  CASHBACK_APPLIED: 'Remises appliquée',
  PROBLEM_WITH_YOUR_CODE: 'Un problème avec votre NIP? Utilisez notre',
  PAGE: 'page',
  SEE_THE: 'Consultez les',
  AND: 'et',
  FOR_TELCO_REWARD: 'de Recompenses Telco',
  BACK_TO_SHOPPING: 'Retour aux achats',
  COPIED_TO_CLIPBOARD: 'Copié dans le presse-papier',
  FAILED_TO_COPY: 'Échec de la copie',
  NO_RESULTS_FOUND: 'Aucun résultat trouvé',
  SALE_IS_FINAL:
    'La vente est définitive. Les achats ne sont pas remboursables',
  BUY_TOP_UP: 'Achetez des recharges prépayées',
  PREPAID_TOP_UPS_AND_EARN: 'et gagnez des remises',
  LOADING: 'Le chargement',
};

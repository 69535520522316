import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CarriersService from '../../api/services/CarriersService';
import { getError } from '../../utils/helpers';

const initialState = {
  carriersList: [],
  itemsCount: 0,
  showHowItWorks: process.env.NODE_ENV === 'development',
  carrier: {
    id: '',
    title: '',
    termsEn: '',
    termsFr: '',
    baseReward: '',
    specialReward: '',
    specialRewardStart: '',
    slag: '',
    translations: [],
    fixedValues: [],
  },
  search: '',
  isLoading: false,
};

export const getCarriers = createAsyncThunk(
  'carriers/getCarriers',
  async (params, thunkAPI) => {
    try {
      const response = await CarriersService.getCarriers(params);
      return await response.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCarrier = createAsyncThunk(
  'carriers/getCarrier',
  async (params, thunkAPI) => {
    try {
      const response = await CarriersService.getCarrier(params);
      return await response.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCarrierById = createAsyncThunk(
  'carriers/getCarrier',
  async (params, thunkAPI) => {
    try {
      const response = await CarriersService.getCarrierById(params);
      return await response.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const carriersSlice = createSlice({
  name: 'carriers',
  initialState,
  reducers: {
    carrierReset: (state) => {
      state.carriersList = initialState.carriersList;
      state.carrier = initialState.carrier;
      state.itemsCount = initialState.itemsCount;
      state.search = initialState.search;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setIsShowHowItWorks: (state, action) => {
      state.showHowItWorks = action.payload;
    },
  },

  extraReducers: {
    [getCarriers.fulfilled]: (state, action) => {
      if (action.meta.arg.page === 1) {
        state.carriersList = action.payload.items;
      } else {
        state.carriersList = [...state.carriersList, ...action.payload.items];
      }
      state.isLoading = false;
      state.itemsCount = action.payload.count;
    },
    [getCarriers.pending]: (state) => {
      state.isLoading = true;
    },
    [getCarriers.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCarrier.fulfilled]: (state, action) => {
      state.carrier = action.payload;
      state.isLoading = false;
    },
    [getCarrier.pending]: (state) => {
      state.isLoading = true;
    },
    [getCarrier.rejected]: (state) => {
      state.isLoading = false;
    },
    [getCarrierById.fulfilled]: (state, action) => {
      state.carrier = action.payload;
      state.isLoading = false;
    },
    [getCarrierById.pending]: (state) => {
      state.isLoading = true;
    },
    [getCarrierById.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { carrierReset, setSearch: setCarrierSearch } =
  carriersSlice.actions;
export default carriersSlice.reducer;

import axios from 'axios';

export default class BlogService {
  static async getBlogs(params) {
    return axios.get('/blog-posts', { params });
  }

  static async getBlogById(blogId) {
    return axios.get(`/blog-posts/${blogId}`);
  }

  static async getBlogBySlug(slug) {
    return axios.get(`/blog-posts/alias/${slug}`);
  }
}

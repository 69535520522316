import React from 'react';
import { hydrate, render } from 'react-dom';

import i18n from './i18n'; // eslint-disable-line no-unused-vars

import App from './App';

import './index.css';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}

reportWebVitals();

import axios from 'axios';

export default class CarriersService {
  static async getCarriers(params) {
    return axios.get('/carriers/paginated', {
      params: { ...params },
    });
  }

  static async getCarrier(parameters) {
    const { id, ...rest } = parameters;
    return axios.get(`/carriers/alias/${parameters.id}`, {
      params: { ...rest },
    });
  }

  static async getCarrierById(parameters) {
    const { id, ...rest } = parameters;
    return axios.get(`/carriers/${parameters.id}`, {
      params: { ...rest },
    });
  }
}

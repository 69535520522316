import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export const useCheckIsCarrierRoute = () => {
  const location = useLocation();

  const isCarrier = useMemo(() => {
    return location.pathname.includes('topup-pin');
  }, [location]);

  return { isCarrier };
};

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import CheckoutService from '../../api/services/CheckoutService';
import { getError } from '../../utils/helpers';

const initialState = {
  billingInformation: {
    price: 0,
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    address: '',
    city: '',
    zipCode: '',
    country: '',
    province: '',
  },
  cashback: 0,
  totalPrice: 0,
  taxesList: [],
  isLoading: false,
};

export const getTaxes = createAsyncThunk(
  'checkout/getTaxes',
  async (body, thunkAPI) => {
    try {
      const response = await CheckoutService.getTaxes(body);
      return await response.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const doPayment = createAsyncThunk(
  'checkout/doPayment',
  async (body, thunkAPI) => {
    try {
      const response = await CheckoutService.doPayment(body);
      return await response.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    checkoutReset: (state) => {
      state.billingInformation = initialState.billingInformation;
      state.cashback = initialState.cashback;
      state.totalPrice = initialState.totalPrice;
      state.taxesList = initialState.taxesList;
      state.language = initialState.language;
      state.isLoading = initialState.isLoading;
    },
    setBillingInformation: (state, action) => {
      state.billingInformation = action.payload.billingInformation;
      state.taxesList = action.payload.taxesList;
    },
    setTotalPriceAndCashback: (state, action) => {
      state.cashback = action.payload.cashback;
      state.totalPrice = action.payload.totalPrice;
    },
  },

  extraReducers: {
    [getTaxes.fulfilled]: (state, action) => {
      const { amount_total, ...taxesList } = action.payload;
      state.totalPrice = amount_total;
      state.taxesList = Object.entries(taxesList).map((tax) => ({
        name: tax[0].toUpperCase(),
        value: tax[1],
      }));
      state.isLoading = false;
      state.itemsCount = action.payload.count;
    },
    [getTaxes.pending]: (state) => {
      state.isLoading = true;
    },
    [getTaxes.rejected]: (state) => {
      state.isLoading = false;
    },

    [doPayment.fulfilled]: (state, action) => {
      const { amount_total, ...taxesList } = action.payload;
      state.totalPrice = amount_total;
      state.taxesList = Object.entries(taxesList).map((tax) => ({
        name: tax[0].toUpperCase(),
        value: tax[1],
      }));
      state.isLoading = false;
      state.itemsCount = action.payload.count;
    },
    [doPayment.pending]: (state) => {
      state.isLoading = true;
    },
    [doPayment.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const {
  checkoutReset,
  setBillingInformation,
  setTotalPriceAndCashback,
} = checkoutSlice.actions;
export default checkoutSlice.reducer;

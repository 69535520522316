import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import InfoService from '../../api/services/InfoService';
import { getError } from '../../utils/helpers';

const initialState = {
  info: null,
  isLoading: false,
};

export const getInfo = createAsyncThunk(
  'info/getInfo ',
  async (type, thunkAPI) => {
    try {
      const response = await InfoService.getInfo(type);
      return response?.data?.translations;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const sendForm = createAsyncThunk(
  'info/sendForm ',
  async (type, thunkAPI) => {
    try {
      const response = await InfoService.sendForm(type);
      return response?.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getInfo.fulfilled]: (state, action) => {
      state.info = action.payload;
      state.isLoading = false;
    },
    [getInfo.pending]: (state) => {
      state.isLoading = true;
    },
    [getInfo.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { reset } = infoSlice.actions;
export default infoSlice.reducer;

import React from 'react';
import { Link as LinkUI } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';

const BuyTopUpPinButton = ({ hidden = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  if (hidden) {
    return null;
  }

  return (
    <div className={classes.linkContainer}>
      <LinkUI
        component={Link}
        to='/buy-topup-pin'
        underline='none'
        color='white'
        className={classes.link}
      >
        <span className={classes.linkText}>{t('BUY_TOP_UP_PIN')}</span>
      </LinkUI>
    </div>
  );
};

export default BuyTopUpPinButton;

const useStyles = makeStyles((theme) => ({
  linkContainer: {
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    background: '#33CC55',
    borderRadius: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    border: '1px solid #EAEAEA ',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      padding: 0,
      height: '100%',
    },
  },
  link: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexGrow: 1,
    height: '100%',
  },
  linkText: {
    padding: '14px 16px',
    [theme.breakpoints.down('sm')]: {
      padding: '12px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '8px',
    },
  },
}));

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import BlogService from '../../api/services/BlogService';
import { getError } from '../../utils/helpers';

const initialState = {
  blogs: [],
  selectedBlog: null,
  totalCount: 0,
  isLoading: false,
};

export const getBlogs = createAsyncThunk(
  'blogs/getBlogs ',
  async ({ params }, thunkAPI) => {
    try {
      const response = await BlogService.getBlogs(params);
      return response?.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getBlogById = createAsyncThunk(
  'blogs/getBlogById ',
  async (blogId, thunkAPI) => {
    try {
      const response = await BlogService.getBlogById(blogId);
      return response?.data;
    } catch (error) {
      toast.error(getError(error));
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getBlogBySlug = createAsyncThunk(
  'blogs/getBlogBySlug ',
  async (slugOrId, thunkAPI) => {
    try {
      const response = await BlogService.getBlogBySlug(slugOrId);
      return response?.data;
    } catch (error) {
      return (await thunkAPI.dispatch(getBlogById(slugOrId))).payload;
    }
  },
);

const blogSlice = createSlice({
  name: 'blogs',
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: {
    [getBlogs.fulfilled]: (state, action) => {
      state.blogs = action.payload.items;
      state.totalCount = action.payload.totalCount;
      state.isLoading = false;
    },
    [getBlogs.pending]: (state) => {
      state.isLoading = true;
    },
    [getBlogs.rejected]: (state) => {
      state.isLoading = false;
    },
    [getBlogById.fulfilled]: (state, action) => {
      state.selectedBlog = action.payload;
      state.isLoading = false;
    },
    [getBlogById.pending]: (state) => {
      state.isLoading = true;
    },
    [getBlogById.rejected]: (state) => {
      state.isLoading = false;
    },
    [getBlogBySlug.fulfilled]: (state, action) => {
      state.selectedBlog = action.payload;
      state.isLoading = false;
    },
    [getBlogBySlug.pending]: (state) => {
      state.isLoading = true;
    },
    [getBlogBySlug.rejected]: (state) => {
      state.isLoading = false;
    },
  },
});

export const { reset } = blogSlice.actions;
export default blogSlice.reducer;

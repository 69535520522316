const keyName = 'LANG';

export const getLanguageFromStore = () => {
  return JSON.parse(localStorage.getItem(keyName));
};

export const setLanguageToStore = (language) => {
  const languageConfig = {
    id: language,
    title: language.toUpperCase(),
  };
  localStorage.setItem(keyName, JSON.stringify(languageConfig));
};
